@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import 'mapbox-gl/dist/mapbox-gl.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, :root {
    height: 100vh;
    font-family: 'Cardo', serif;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Lora', serif;
    text-align: center;
    padding: 0.5rem;
    font-size: inherit;
    font-weight: inherit;
}

.title {
    font-family: Great Vibes, cursive;
}